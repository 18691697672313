import moment from 'moment'
export default {
    SET_DRAWER_Z_INDEX(state, value = null) {
        if(value) {
            state.drawerZIndex = value
        } else {
            state.drawerZIndex = 801
        }
    },
    SET_DRAWER_VISIBLE(state, value) {
        state.visible = value
    },
    SET_VALUE_STATE(state, { name, data }) {
        state[name] = data
    },
    SET_NEXT(state, value) {
        state.next = value
    },
    SET_PAGE(state, value) {
        state.page = value
    },
    SET_LIST_NOTY(state, data) {
        if (state.listNoty.length === 0) {
            state.listNoty = data.results
        } else {
            let notList = []

            data.results.forEach(item => {
                const find = state.listNoty.find(f => f.id === item.id)
                if(!find) {
                    notList.push(item)
                }
            })

            state.listNoty = state.listNoty.concat(notList)
        }
    },
    SET_LIST_NOTY_BELL(state, data) {
        if (state.listNotyBell.length === 0)
            state.listNotyBell = data.results
        else
            state.listNotyBell = state.listNotyBell.concat(data.results)
    },
    clearList(state) {
        state.listNoty = []
    },
    READ_NOTY(state, { id }) {
        state.listNoty = state.listNoty.map(el => {
            let res = el
            if (id === 'all') {
                res.is_read = true
            }
            else if (id === el.id) {
                res.is_read = true
            }


            return res
        })

        if (id === "all") {
            this.commit('REMOVE_PWA_COUNTER', { name: 'noty', value: state.unreadCount })
            state.unreadCount = 0
            state.listNotyBell = []

        }
        else {
            this.commit('DICRIMENT_PWA_COUNTER', 'noty')
            state.unreadCount--
            state.listNotyBell = state.listNotyBell.filter(el => el.id !== id)
        }
    },
    addNotyFromSocket(state, data) {
        let findList = state.listNoty.findIndex(el => el.id === data.id)
        let findBell = state.listNoty.findIndex(el => el.id === data.id)

        if (findList === -1) {
            state.listNoty.unshift({
                ...data,
                created_at: moment(data.created_at).add(-1, 'seconds')
            })
        } if (findBell === -1)
            state.listNotyBell.unshift({
                ...data,
                created_at: moment(data.created_at).add(-1, 'seconds')
            })
        state.unreadCount++
        this.commit('INCRIMENT_PWA_COUNTER', 'noty')
    }
}