
export const getColor = (name) => {
    switch (name) {
    case "success": return 'rgba(135, 208, 104, 1);'
    case "primary": return 'rgba(29, 101, 192, 1);'
    case "info": return 'rgba(45, 183, 245, 1);'
    case "warning": return 'rgba(250, 158, 58, 1);'
    case "error": return 'rgba(246, 59, 69, 1);'
    }
}

export const getBackground = (name) => {
    switch (name) {
    case "success": return 'rgba(135, 208, 104, .1);'
    case "primary": return 'rgba(29, 101, 192, .1);'
    case "info": return 'rgba(45, 183, 245, .1);'
    case "warning": return 'rgba(250, 158, 58, .1);'
    case "error": return 'rgba(246, 59, 69, .1);'
    }
}
