import { mapMutations, mapState, mapActions } from 'vuex';
import eventBus from '@/utils/eventBus';
let playSong = false;
export default {
    computed: {
        ...mapState({
            config: state => state.config.config
        })
    },
    methods: {
        ...mapMutations({
            addNotyFromSocket: "notifications/addNotyFromSocket"
        }),
        playAudio() {
            try {
                if(this.config?.sounds?.new_notify) {
                    if(playSong) {
                        this.config.sounds.new_message.pause()
                        this.config.sounds.new_message.currentTime = 0
                        playSong = false
                    } else {
                        playSong = true
                    }

                    const pNotify = this.config.sounds.new_notify.play()
                    pNotify.then(_ => {
                        playSong = false
                    }).catch(error => {
                        playSong = false
                    })
                }
            } catch (e) {
                playSong = false
            }
        }
    },
    sockets: {
        notify({ data }) {
            let res = data.obj
            res.message = res[`message_${this.$i18n.locale}`] ? res[`message_${this.$i18n.locale}`] : res.message
            res.icon_name = res[`icon_name_${this.$i18n.locale}`] ? res[`icon_name_${this.$i18n.locale}`] : res.icon_name
            this.playAudio()
            eventBus.$emit('NOTIFICATION_NEW_MESSAGE', res)
            this.addNotyFromSocket(res)
        }
    }
}