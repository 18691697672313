<template>
    <div  class="w-full nd_item relative ">
        <div v-if="!bell" class="flex items-center justify-between w-full">
            <div class="flex w-full">
                <div class="shrink-0">
                    <a-avatar
                        :class="isMobile && 'mob_a'"
                        :style="`color: ${getColor(item.color)};backgroundColor: ${getBackground(item.color)}`"
                        :icon="item.icon"
                        size="large" />
                </div>
                <div  class="ml-2 overflow-hidden">
                    <div  class="flex items-center ">
                        <span class="font-semibold text-base"
                              :class="{'mob-text-base':isMobile}">
                            {{item.icon_name}}
                        </span>
                        <a-tag v-if="!item.is_read && !isMobile" color="red"
                               class="ml-2">
                            Непрочитанное
                        </a-tag>
                        <a-badge v-if="!item.is_read && isMobile" 
                                 status="error" 
                                 class="ml-1" />
                        <span class="ml-3 font-light" 
                              :class="{'mob-text-sm':isMobile}">{{date}}</span>
                    </div>
                    <div class="flex lg:truncate overflow-hidden w-full">
                        <div class="text-sm overflow-hidden"
                             :class="{'mob-text-base':isMobile}">
                            <Messsage  :item="item" @read="read()"  /> 
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isMobile" class="read_b ml-2">
                <a-button 
                    v-if="!item.is_read"
                    shape="circle"
                    icon="fi-rr-check"
                    flaticon
                    @click="read" 
                    class="flex items-center justify-center text-xs lg:text-sm" />
            </div>
            <div v-if="!item.is_read && !isMobile">
                <a-checkbox @change="read" class="whitespace-nowrap">
                    Прочитано
                </a-checkbox>
            </div>
        </div>
        <div v-else class="flex items-center justify-between">
            <div  class="flex ">
                <div class="mr-3" @click="pushRoute">
                    <a-avatar
                        :style="`color: ${getColor(item.color)};
                backgroundColor: ${getBackground(item.color)}`"
                        :icon="item.icon"
                        size="default" />
                </div>
                <div class="text-sm ">
                    <!-- <div class="font-semibold truncate">{{item.name}}</div> -->
                    <Messsage  :item="item" @read="read()"/>
                    <div class="notify_date text-sm">
                        {{date}}
                    </div>
                </div>
            </div>
            <div class="read_btn absolute cursor-pointer z-10 items-center justify-center">
                <a-icon @click="read" type="close"  />
            </div>
        </div>
    </div>
</template>

<script>
import {getColor, getBackground} from '../utils'
import Messsage from './Message'
export default {
    name: "NotificationMessageItem",
    components: {Messsage},
    props: {
        item: {
            type: Object,
            required: true
        },
        bell: {
            type: Boolean,
            default: false
        }
    },
   
    computed: {
        date() { 
            let current = this.$moment(),
                days = this.$moment(this.item.created_at).diff(current, 'days')

            if(days < -2)
                return this.$moment(this.item.created_at).format('DD.MM.YYYY HH:mm')
            else {
                return this.$moment(this.item.created_at).fromNow()
            }
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        getColor,
        getBackground,
        read(){
            this.$emit('read', this.item)
        },
        pushRoute(){
            if(this.$route.name !=='notifications')
                this.$router.push({name: 'notifications'})
            this.$emit('close')   
        }
    }
}
</script>

<style lang="scss" scoped>
.read_b{
    min-width: 32px;
}
.read_btn {
  background: #fff;
  width: 31px;
  height: 31px;
  top: 2px;
  right: 1px;
  border-radius: 50%;
  display: none;
}
.mob_a{
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
}
.nd_item {
  &:hover {
    .read_btn {
      display: flex;
    }
  }
}

.mob-text-sm {
    font-size: 0.8rem;
    line-height: 1.5;
}
.mob-text-base {
    font-size: 1rem;
    line-height: 1.5;

}
</style>