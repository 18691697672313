<template>
    <NotifyDrawer />
</template>

<script>
import eventBus from '@/utils/eventBus';
import store from "./store/index"
import Socket from './socket'
import { mapActions, mapState } from 'vuex'
import {getColor, getBackground} from './utils'
import push from './mixins/push'
import NotifyDrawer from './views/NotifyDrawer.vue'
export default {
    name: "NotificationInit",
    components: {
        NotifyDrawer
    },
    mixins: [
        Socket, 
        push
    ],
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
            notifyDrawer: state => state.notifications.visible
        })
    },
    methods: {
        ...mapActions({
            getUnreadCount: 'notifications/getUnreadCount'
        }),
        openNoty() {
            if(!this.notifyDrawer)
                this.$store.commit('notifications/SET_DRAWER_VISIBLE', true)
            else
                this.$store.commit('notifications/SET_DRAWER_Z_INDEX', 5000)
            
            this.$notification.destroy()
        }
    },
    created() {
        if(!this.$store.hasModule('notifications'))
            this.$store.registerModule("notifications", store)
        
        this.getUnreadCount()
    },
    mounted() {
        eventBus.$on('NOTIFICATION_NEW_MESSAGE', data => {
            if(this.isMobile)
                this.$notification.destroy()

            const key = data.id,
                title = data.icon_name

            this.$notification.open({
                message: (h) => {
                    return h('div', { class: 'notify_head' }, [h('div', { class: 'notify_title' }, title), h('div', { class: 'notify_date' }, `${this.$moment(data.created_at).format('HH:mm')}`)])
                },
                description: (h) => {
                    return h('div', { class: 'notify_message', domProps: { innerHTML: `${data.message}` } })
                },
                duration: 7,
                top: this.isMobile ? '50px' : '60px',
                key,
                class: 'cursor-pointer notify_popover',
                closeIcon: (h) => {
                    if(this.isMobile) {
                        return h('div', { class: 'notify_close' }, 'Закрыть')
                    } else
                        return h('i', { class: 'fi fi-rr-cross' })
                },
                icon: (h) => {
                    return h('a-avatar', {
                        attrs: {
                            style: `color: ${getColor(data.color)};backgroundColor: ${getBackground(data.color)}`,
                            icon: data.icon
                        }
                    })
                }, 
                onClick: ()=> this.openNoty(),
                onClose: this.$notification.close(key)
            })

        })
    }
}
</script>